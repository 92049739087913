import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

// CSS
import 'styles/WatIsKavelruil.scss'

// Components
import {
  BannerDefault,
  TitleDefault,
  TitleAlt,
  TitleSubDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import ContactForm from '../components/ContactForm'

// Images
import arrow from '../img/arrow-menu.svg'
import arrowWhite from '../img/arrow-white.svg'

function WatIsKavelruilPage({ data }) {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="kavelruil-page">
        <BannerDefault>
          <div className="row">
            <Img
              className="position-absolute banner-image"
              fluid={
                page.acf.kavelruil_header.banner_image.localFile.childImageSharp
                  .fluid
              }
            />
            <div className="col-12 col-lg-4 pb-4">
              <TitleDefault>
                {page.acf.kavelruil_header.header_title}
              </TitleDefault>
            </div>
          </div>
        </BannerDefault>
        <section className="content-row">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 pt-5">
                <TitleAlt>
                  <img src={arrow} alt="" width="18" />
                  {page.acf.page_content.title}
                </TitleAlt>
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.acf.page_content.content,
                  }}
                />
              </div>
              <div className="col-lg-1 d-lg-block" />
              <div className="col-lg-4 sidebar-cta">
                <div className="sidebar-inner py-5 px-4 mb-5">
                  <TitleSubDefault>
                    <img src={arrowWhite} alt="" width="18" />
                    {page.acf.formulier_title}
                  </TitleSubDefault>
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="over-het-boek">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.acf.about_book.text_book,
                  }}
                />
                <Link to="/boek" className="btn">
                  {page.acf.about_book.button_text}
                  <img
                    src={arrow}
                    alt="arrow"
                    width="18"
                    className="button-img"
                  />
                </Link>
              </div>
              <div className="col-lg-1 d-lg-block" />
              <div className="col-lg-4 image-book">
                <Img
                  className="book position-absolute"
                  fluid={
                    page.acf.about_book.image_book.localFile.childImageSharp
                      .fluid
                  }
                />
              </div>
            </div>
          </div>
        </section>
        <div className="col-12 empty-space-120" />
      </div>
    </Layout>
  )
}

export default WatIsKavelruilPage

export const kavelruilQuery = graphql`
  query KavelRuil {
    wordpressPage(wordpress_id: { eq: 43 }) {
      title
      acf {
        kavelruil_header {
          header_title
          banner_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1700) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        page_content {
          content
          title
        }
        formulier_title
        about_book {
          button_text
          text_book
          image_book {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 424) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`
